export default function(doc) {
	if( doc.link_type == 'Web' ) {
		return doc.url
	}
	return `/${doc.type}/${doc.uid}`
	// blog posts
	// if (doc.type === "blog") {
	// 	return "/blog/" + doc.uid;
	// }
	// Otherwise, return a 404
	// return "/404";
}