<template>
	<article>
		<a :href="`/content/${post.type}/${post.uid}`">
			<prismic-image 
				v-if="post.data.header_image.hasOwnProperty('url')"
				class="rounded border border-gray-200"
				:field="post.data.header_image.thumbnail"
				loading="lazy"
			></prismic-image>
			<img 
				v-else
				loading="lazy"
				class="border border-gray-200 rounded"
				src="../../../../public/images/blog-placeholder.jpg"
				alt=""
			>
		</a>
		<div class="pt-6">
			<h4 class="mb-4 text-xl text-gray-800 font-medium">
				<a 
					:href="`/content/${post.type}/${post.uid}`"
					v-html="$prismic.asText(post.data.title)"
				></a>
			</h4>
			<div class="mb-6">
				<p v-html="getExcerpt()"></p>
			</div>
			<footer class="flex items-center">
				<a 
					v-if="post.data.category.hasOwnProperty('uid')"
					:href="`/content/category/${post.data.category.uid}`"
					class="block py-2 px-4 rounded bg-gray-100 text-gray-800"
					v-html="post.data.category.data.name"
				></a>
				<p class="ml-auto">
					<span v-html="getDate(post.first_publication_date)"></span>
				</p>
			</footer>
		</div>
	</article>
</template>

<script>
	
	export default {

		props: ['post'],

		data() {
			return {
				excerptLength: 200,
			}
		},

		methods: {

			getExcerpt() {
				if( this.post.data.excerpt ) {
					return this.post.data.excerpt
				}
				let excerpt = ''
				const slices = this.post.data.body.filter(slice => {
					return slice.slice_type === 'text_slice'
				})
				if( slices.length === 0 ) {
					return excerpt
				}
				slices.forEach(slice => {
					if( excerpt.length < this.excerptLength ) {
						excerpt += ' ' + slice.primary.content.map(elem => {
							return elem.type == 'paragraph' ? elem.text : null
						}).join(' ')
					}
				})
				const length = excerpt.length
				excerpt = excerpt.substring(0, this.excerptLength)
				if( length > this.excerptLength ) {
					excerpt += '...'
				}
				return excerpt
			},

			getDate(date) {
				date = new Date(date)
				return date.toLocaleDateString("en-GB")
			},
		},
	};

</script>