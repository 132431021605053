import { createPrismic } from "@prismicio/vue"

import linkResolver from "./link-resolver.js"

const prismic = createPrismic({
	endpoint: 'https://givetastic.cdn.prismic.io/api/v2',
	clientConfig: {
		accessToken: 'MC5aRlRud1JFQUFDUUFhZWpT.77-9ZzPvv73vv73vv71Kdu-_ve-_ve-_vXnvv71677-9KO-_ve-_ve-_vRbvv73vv71w77-9ZiBGBu-_ve-_ve-_ve-_vQ'
	},
	linkResolver,
})

export default prismic